<template>
  <v-container
    id="dashboard-view"
    fluid
    tag="section"
  >
    <h1>{{ lessons.length }}</h1>
    <v-row>
      <v-col cols="12">
        <v-row>
          <v-col
            v-for="(lesson, i) in lessons"
            :key="`chart-${i}`"
            cols="12"
            md="6"
            lg="4"
          >
            <material-dashboard-card
              :color="lesson.bgColor"
              :data="chart.data"
              :options="chart.options"
              :responsive-options="chart.responsiveOptions"
              :title="lesson.name"
              :type="chart.type"
              :asuulga-id="lesson.id"
              @click="_goDetail"
            >
              <template #subtitle>
                <div class="font-weight-light text--secondary">
                  <div v-html="chart.subtitle" />
                </div>
              </template>

              <template #actions>
                <v-icon
                  class="mr-1"
                  small
                >
                  mdi-clock-outline
                </v-icon>

                <span
                  class="text-caption grey--text font-weight-light"
                  v-text="chart.time"
                />
              </template>
            </material-dashboard-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  // Utilities
  import { get } from 'vuex-pathify'
  import Vue from 'vue'
  const fb = require('../firebaseConfig.js')
  const lineSmooth = Vue.chartist.Interpolation.cardinal({
    tension: 0,
  })

  export default {
    name: 'DashboardView',

    data: () => ({
      chart:
        {
          type: 'Line',
          color: 'success',
          title: 'Daily Sales',
          subtitle: '<i class="mdi mdi-arrow-up green--text"></i><span class="green--text">55%</span>&nbsp;increase in today\'s sales',
          time: 'updated 4 minutes ago',
          data: {
            labels: ['12am', '3pm', '6pm', '9pm', '12pm', '3am', '6am', '9am'],
            series: [
              [230, 750, 450, 300, 280, 240, 200, 190],
            ],
          },
          options: {
            lineSmooth,
            low: 0,
            high: 1000, // creative tim: we recommend you to set the high sa the biggest value + something for a better look
            chartPadding: {
              top: 0,
              right: 0,
              bottom: 0,
              left: 0,
            },
          },
        },
      lessons: [],
    }),
    computed: {
      sales: get('sales/sales'),
      totalSales () {
        return this.sales.reduce((acc, val) => acc + val.salesInM, 0)
      },
    },
    created() {
      fb.db
        .collection('asuulga')
        .orderBy('createdAt', 'desc')
        .onSnapshot((querySnapshot) => {
          this.lessons = []
          querySnapshot.forEach((doc) => {
            const object = doc.data()
            object.id = doc.id
            this.lessons.push(object)
          })
        })
    },
    methods: {
      _goDetail () {
        console.log('dddd')
        // this.$router.push({ name: 'QDetail' })
      },
    },
  }
</script>
